import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  // checkIcon = faCheck;
  confirmationData: any = {};

  constructor(private router: Router) {}

  ngOnInit(): void {
    // console.log('history', history.state)
    // this.confirmationData = history.state
  }

  ngAfterViewInit(): void {
    // console.log('history in afterviewinit', history.state)
  }
  
  navigateToNextStep() {

  }

  navigateToAccountSummary() {
    this.router.navigateByUrl('')
  }

}
