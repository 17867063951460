import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  // Dev
  key = btoa(environment.conektaKey)
  baseUrl = environment.apiBaseUrl
  
  // Prod
  // key = btoa('')
  // baseUrl = ''

  constructor(private http: HttpClient) { }

  getTokens() {
    const headers = {}
    const body = {}
    const endpoint = this.baseUrl + 'phoenix/v1/conekta/token'
    return this.http.post<any>(endpoint, body, { headers })
  }

  createConektaCustomer(customer) {
    const {tokenId, name, email, phone} = customer
    // console.log('Token ID', tokenId)
    const headers = {}
    const body = {
      name: name,
      email: email,
      phone: phone,
      payment_sources: [
        {
          type: "card",
          token_id: tokenId,
          default: "true"
        }
      ]
    }
    const endpoint = this.baseUrl + 'phoenix/v1/conekta/customers'

    return this.http.post<any>(endpoint, body, { headers })
  }

  createOxxoCustomer(customer) {
    // console.log('OXXO Customer Info', customer)
    const {tokenId, name, email, phone} = customer
    // console.log('Token ID', tokenId)
    const headers = {}
    const body = {
      "name": "Fulanito",
      "email": "fulanito@test.com",
      "phone": "+5218181818181",
      "payment_sources": [{
      "type": "oxxo_recurrent"
      }]
    }

    const endpoint = this.baseUrl + 'phoenix/v1/conekta/customers'

    return this.http.post<any>(endpoint, body, { headers })
  }


  // createOxxoCustomer(customer) {
  //   console.log('OXXO Customer Info', customer)
  //   const {tokenId, name, email, phone} = customer
  //   // console.log('Token ID', tokenId)
  //   const headers = {}
  //   const body = {
  //     name: name,
  //     email: email,
  //     phone: phone,
  //     payment_sources: [
  //       {
  //         type: 'oxxo_recurrent',
  //       }
  //     ]
  //   }
  //   const endpoint = this.baseUrl + 'phoenix/v1/conekta/customers'

  //   return this.http.post<any>(endpoint, body, { headers })
  // }


  getConektaCustomer(customerId) {
    const headers = {}

    let endpoint = this.baseUrl + 'phoenix/v1/conekta/customers/' + customerId

    return this.http.get<any>(endpoint, { headers })
  }

  deleteConektaPaymentSource(customerId, paymentSourceId) {
    const headers = {}

    let endpoint = this.baseUrl + 'phoenix/v1/conekta/customers/' + customerId + '/payment-sources/' + paymentSourceId

    return this.http.delete<any>(endpoint, { headers })
  }


  createNewConektaPaymentSource(customerId, tokenId) {
    const headers = {
      'Authorization': 'Basic ' + this.key,
      'Accept': 'application/vnd.conekta-v2.0.0+json',
      'Accept-Language': 'es'
    }

    let endpoint = this.baseUrl + 'phoenix/v1/conekta/customers/' + customerId + '/payment-sources'

    let body = {
      "type": "card",
      "token_id": tokenId,
      "default": true
    }

    return this.http.post<any>(endpoint, body, { headers })
  }



  placeConektaVerificationOrder(customerId, paymentSourceId, opportunityId) {
    const headers = {}

    const body = {
      currency: "MXN",
      customer_info: {
        customer_id: customerId
      },
      metadata: {
        integration: "system",
        internal_order_id: opportunityId
      },
      line_items: [
        {
          name: "Card Verification Payment",
          unit_price: 300,
          quantity: 1
        }
      ],
      charges: [
        {
          payment_method: {
            payment_source_id: paymentSourceId,
            type: "card"
          }
        }
      ]

    }

    let endpoint = this.baseUrl + 'phoenix/v1/conekta/orders'

    return this.http.post<any>(endpoint, body, { headers })

  }

  placeOxxoVerificationOrder(customerId, paymentSourceId, opportunityId) {
    const headers = {}

    const body = {
      currency: "MXN",
      customer_info: {
        customer_id: customerId
      },
      metadata: {
        integration: "system",
        internal_order_id: opportunityId
      },
      line_items: [
        {
          name: "Oxxo Payment",
          unit_price: 300,
          quantity: 1
        }
      ],
      charges: [
        {
          payment_method: {
            type: "oxxo_cash",
            expiration_at: "1666304105"
          }
        }
      ]

    }

    let endpoint = this.baseUrl + 'phoenix/v1/conekta/orders'

    return this.http.post<any>(endpoint, body, { headers })

  }

  putConektaIdsToSalesforce(idsObj) {
    const {oppId, customerId, paymentSourceId, oxxoCustomerId, oxxoReferenceId} = idsObj
    let options = {}
    let body = {
      "conektaCustomerId": customerId,
      "conektaPaymentSourceId": paymentSourceId,
      "oxxoCustomerId": oxxoCustomerId,
      "oxxoReferenceId": oxxoReferenceId
    }

    console.log('Sending these Ids to salesforce', body)
    let url = this.baseUrl + 'phoenix/v1/opportunities/'+ oppId + '/conekta'
    return this.http.put(url, body, options)
  }

  getMerchantDetailsFromSalesforce(oppId) {
    const options: any = {
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'text/plain',
        apiToken: 'E67D1A51B59D6CBA128F22B2EE23C',
      })
    }
    // let oppId = '006S000000MkK4rIAF'
    let url = this.baseUrl + 'phoenix/v1/opportunities/' + oppId

    return this.http.request('get', url, options)

  }

}