<section id="content" class="container-fluid container-xl mt-4 mt-lg-5">
  <!-- <app-loader *ngIf="isLoading"></app-loader> -->
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xxl-8">
      <h2 *ngIf="isLoading && !isLoadingConfirm" class="fs-5 text-primary pb-2">Cargando...</h2>
      <div *ngIf="!isLoading" class="row">
        <div class="col-12">
          <h1 class="fw-normal text-secondary fs-2">
            Hola, {{merchantName}}.
          </h1>
          <h2 class="fs-5 text-primary pb-2">Por favor introduce los datos solicitados.</h2>
          <p class="text-primary font-weight-normal pb-2">
            Para validar tu tarjeta de débito realizaremos un cargo de tres pesos. En caso de que el cargo no sea
            exitoso te solicitaremos otro medio de pago Nota: Por regulación no podemos aceptar tarjetas de crédito.
          </p>
        </div>
      </div>
      <div class="d-flex bg-secondary text-white text-center justify-content-center align-self-center">
        <div class="ms-2 me-auto align-self-center">
          <h2 class="fs-5 m-0 p-3 text-capitalize fw-normal">
            <a (click)="navigateToNextStep()">
              <i class="fa-regular fa-lock"></i> Seguro
            </a>
          </h2>
        </div>
      </div>
      <div class="shadow-lg p-3 p-lg-4 justify-content-center" style="min-height: 500px; background-color: rgb(255, 255, 255)">
        <app-loader *ngIf="isLoadingConfirm" [validationText]="true"></app-loader>
        <!-- <div *ngIf="!isLoading" class="row">
          <div class="col-12 text-center ">
            <p><a href="#" role="button" class="btn btn-primary btn-lg mt-3">Make Payment</a></p>
            <p><i class="fa-regular fa-lock"></i> Seguro</p>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12">
            <div *ngIf="showIframe" id="conektaIframeContainer" style="height: 575px;"></div>
            <div *ngIf="!showIframe" id="conektaIframeContainer" style="height: 575px; display: none;"></div>
            <!-- <img src="https://via.placeholder.com/800.png?text=Conekta+IFrame" alt=""> -->
          </div>
        </div>

        <div *ngIf="!isLoading && showConfirm" class="row mt-3 mb-3">
          <div class="col-12">
            <h1 class="fw-normal text-secondary fs-2 text-center">¡Gracias!</h1>
            <div class="text-center"> En caso de que experimentemos problemas realizando los cargos a tu tarjeta, recuerda que también puedes realizar tu pago en efectivo utilizando las siguientes referencias</div>

            <div class="reference text-center pt-3 pb-3">
              <h3 class="fs-4 text-primary">Referencia Oxxo</h3>
              <div class="number-container border border-secondary p-3">
                <h1 class="fw-normal text-secondary p-0 m-0">{{oxxoReference}}</h1>
              </div>
              <div class="qr-container">
                <ngx-qrcode
                   [elementType]="qrElementType"
                   [errorCorrectionLevel]="qrCorrectionLevel"
                   [value]="qrValue"
                   cssClass="coolQRCode"></ngx-qrcode>
             </div>
            </div>
            <div class="instructions pt-3">
              <h3 class="fs-4 text-left text-primary">Instrucciones</h3>
              <ul class="text-left text-primary">
                <li>Acude a la tienda Oxxo, Seven Eleven, Farmacias del Ahorro, Circle K, Extra, Waldo’s mas cercana</li>
                <li>Indica al cajero que quieres realizar un pago con Referencia</li>
                <li>Dicta al cajero la referencia</li>
                <li>Realiza el pago indicado por el cajero</li>
                <li>El cajero te entregará un comprobante impreso</li>
              </ul>
              <h3 class="fs-5 text-primary pb-3 text-center">¡Tu tarjeta de débito ha sido actualizado!</h3>
            </div>
          </div>
        </div>

        <div *ngIf="showErrorMsg" class="text-center px-5">
          <h1 class="fw-normal text-secondary fs-2">{{errorMsg}}</h1>
          <h3 class="fs-4 text-primary pb-3">{{errorMsgDetails}}</h3>
          <button class="btn btn-primary-dark btn-lg align-self-center me-1 me-lg-3" role="button" (click)="tryAgainSelected()">
              Try Again
              <!-- <i class="fa-solid fa-right-long"></i> -->
        </button>
        </div>
      </div>

      <!-- <div class="row mt-4 mb-9">
        <div class="col-12 px-4">
          <div>Valid Card: {{testCard}}</div>
          <div>Invalid Card: {{invalidTestCard}}</div>
        </div>
      </div> -->
    </div>
  </div>

</section>