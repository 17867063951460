import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApiService } from '../../services/base-api.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode'

declare global {
  interface Window { 
    ConektaCheckoutComponents: any;
    ConektaToken: any
  }
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})

export class PaymentsComponent implements OnInit {
  window: any;
  ConektaIframe: any = {}
  tokenData: any;
  checkoutId: any;
  tokenId: any;
  customerToken: any;
  testCard = '4915669353237603'
  invalidTestCard = '4000000000000127'
  customerResponseData: any;
  paymentSourceId
  customerId: any = null;
  isLoading: boolean = false;
  isLoadingConfirm: boolean = false;
  showIframe: boolean = true;
  showConfirm: boolean = false;
  merchantDetails: any;
  merchantName: any;
  oppId: any;
  errorMsg: any;
  errorMsgDetails: any;
  showErrorMsg: boolean = false
  errorModal: any;
  key = environment.conektaPublicKey
  firstToken = null
  lastToken = null
  oxxoCustomerId = null
  oxxoReference = null
  qrElementType = NgxQrcodeElementTypes.URL
  qrCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH
  qrValue = null


  private routeSub: Subscription;
  private paramSub: Subscription;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private router: Router,
    private api: BaseApiService,
    private route: ActivatedRoute
  ) {
    this.window = this.document.defaultView
    this.window.name = "Card Capture Component"
    if (!this.window.ConektaCheckoutComponents) {
      this.window.ConektaCheckoutComponents = {}
      this.window.ConektaToken = undefined
    }

    if(!this.window.boostrap) {
      this.window.bootstrap = {}

    }

    if (window.addEventListener) {
      window.addEventListener("message", this.createConektaCustomer.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.createConektaCustomer.bind(this));
    }

  }

  ngOnInit(): void {
    this.isLoading = true

    let routeId

    this.routeSub = this.route.params.subscribe(params => {
      routeId = params['id']
      this.oppId = routeId
      this.getMerchantDetails(this.oppId)
      this.getConektaToken()
    });
    // console.log('Window', this.window)
  }

  getMerchantDetails(oppId) {
    this.api.getMerchantDetailsFromSalesforce(oppId).subscribe(resp => {
      this.merchantDetails = (<any> resp).body
      
      // console.log('Merchant Details', this.merchantDetails)

      this.oppId = this.merchantDetails.id

      if (this.merchantDetails.firstOwner === null || this.merchantDetails.firstOwner.name == null) {
        // console.log('Error: Name missing from merchant object')
      } else {
        this.merchantName = this.merchantDetails.firstOwner.name
      }

      
      this.isLoading = false
    }, err => {
      this.isLoading = true
    })

  }

  async getConektaToken() {
    await this.api.getTokens().subscribe(resp => {
      this.tokenData = resp
      // console.log('Token Data', this.tokenData)
      this.checkoutId = resp.checkout.id
      this.createWindow(this.checkoutId)
    })
  }

  createWindow(checkoutId) {
    this.window.ConektaCheckoutComponents.Card({
      targetIFrame: "#conektaIframeContainer",
      allowTokenization: true,
      checkoutRequestId: checkoutId,
      publicKey: this.key,
      locale: 'es', // 'es' Spanish | 'en' English
      options: {
        styles: {
          inputType: 'rounded', // 'basic' | 'rounded' | 'line'
          buttonType: 'rounded', // 'basic' | 'rounded' | 'sharp'
          colors: {
            primary: "#D32D92" //for button color
          },
          states: {
            empty: {
              borderColor: '#FFAA00'
            },
            invalid: {
              borderColor: '#FF00E0'
            },
            valid: {
              borderColor: '#0079c1'
            }
          }
        },
        button: {
          colorText: '#ffffff',
          buttonPayText: 'Remember Card'
        },
        iframe: {
          colorText: '#65A39B',
        }
      },
      onCreateTokenSucceeded(token) {
        window.ConektaToken = token.id
        // console.log('Customer Token', token)
        // console.log('Window', window)
      },
      onCreateTokenError(error) {
        console.log('Create Token Error', error)
      },
    })
  }

  createConektaCustomer() {
    if(this.firstToken == null) {
      setTimeout(() => {
        if(this.window.ConektaToken == undefined) {
          // console.log('Waiting for customerToken')
        } else {
          this.showIframe = false
          this.isLoadingConfirm = true
          this.customerToken = this.window.ConektaToken
          this.firstToken = this.window.ConektaToken
          this.window.ConektaToken = undefined
          // console.log('Sending Customer with Token:', this.customerToken)
  
          let customerInfo = {
            tokenId: this.customerToken,
            name: this.merchantDetails.firstOwner.name,
            email: this.merchantDetails.firstOwner.email,
            phone: this.merchantDetails.firstOwner.phone
          }
  
          this.api.createConektaCustomer(customerInfo).subscribe(resp => {
            // console.log('Created Customer with Card', resp)
            this.paymentSourceId = resp.default_payment_source_id
            this.customerId = resp.id

            this.placeConektaVerificationOrder(this.customerId, this.paymentSourceId, customerInfo)
          })
        }
      }, 1000)
      
    } else {
      setTimeout(() => {
        if(this.window.ConektaToken == undefined) {
          // console.log('Waiting for new customerToken')
        } else {
          if(this.lastToken == null) {        
            // console.log('Existing customer with token')
            this.showIframe = true;
            this.isLoadingConfirm = false;
            // console.log('first token', this.firstToken)
            this.lastToken = this.window.ConektaToken
            // console.log('last token', this.lastToken)
            if(this.firstToken !== this.lastToken) {
              // console.log('last token', this.lastToken)
              this.showIframe = false;
              this.isLoadingConfirm = true;
              this.updatePaymentSource(this.customerId, this.lastToken)
            }
          }
        }
      })

    }
  }

  updatePaymentSource(customerId, customerToken) {
    // console.log('Updating with token', customerToken)
    this.api.createNewConektaPaymentSource(customerId, customerToken).subscribe(resp => {
      // console.log('Updated Customer Payment Source', resp)
      this.placeConektaVerificationOrder(customerId, resp.id)
    })
  }

  placeConektaVerificationOrder(customerId, paymentSourceId, customerInfo?) {
    this.api.placeConektaVerificationOrder(customerId, paymentSourceId, this.oppId).subscribe(resp => {
      // console.log('Order Placed', resp)
      if (resp.payment_status == 'paid') {
        this.api.createOxxoCustomer(customerInfo).subscribe(resp => {
          // console.log('Oxxo Customer Created', resp)
          this.oxxoCustomerId = resp.id
          this.oxxoReference = resp.payment_sources.data[0].reference
          this.qrValue = resp.payment_sources.data[0].barcode_url

          let idsObj = {
            oppId: this.oppId,
            customerId: customerId,
            paymentSourceId: paymentSourceId,
            oxxoCustomerId: this.oxxoCustomerId,
            oxxoReferenceId: this.oxxoReference
          }

          this.sendIdsToSalesforce(idsObj)
        })
      } else {
        let message = 'Error - Insufficient funds'
        this.showError(message)
        this.api.deleteConektaPaymentSource(customerId, paymentSourceId).subscribe(resp => {
          console.log('Deleted Payment Source', resp)
        })
        this.isLoadingConfirm = false;
        this.lastToken == null
      }
      
    }, err => {
      console.log('Error in order', err)
      let message = err.error.message
      this.showError(message)
      this.api.deleteConektaPaymentSource(customerId, paymentSourceId).subscribe(resp => {
        // console.log('Deleted Payment Source', resp)
      })
      this.isLoadingConfirm = false;
      this.lastToken == null
    })
  }

  getConektaCustomer() {
    this.api.getConektaCustomer(this.customerId).subscribe(resp => {
      // console.log('Got existing customer', resp)
    })
  }

  navigateToNextStep(data?: any) {
    this.router.navigateByUrl('confirmation')
  }

  sendIdsToSalesforce(idsObj) {
    this.api.putConektaIdsToSalesforce(idsObj).subscribe(() => {
      // console.log('Ids successfully sent to Salesforce')
      this.isLoading = false;
      this.isLoadingConfirm = false;
      this.showConfirm = true;
    })
  }

  showError(message) {
    this.showErrorMsg = true;
    this.errorMsg = 'Algo salió mal.'
    this.errorMsgDetails = message
  }

  tryAgainSelected() {
    this.showErrorMsg = false;
    this.showIframe = true;
    this.getConektaToken()
  }

  parseQueryString(str) {
    var vars = [];
    var arr = str.split('&');
    var pair;
    for (var i = 0; i < arr.length; i++) {
      pair = arr[i].split('=');
      vars.push(pair[0]);
      vars[pair[0]] = unescape(pair[1]);
    }
    return vars;
  }

}
