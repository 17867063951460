import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  modifySelected: boolean = false;
  showDefault: boolean = true;
  showChargeOff: boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  defaultSelected() {
    this.showDefault = true;
    this.showChargeOff = false;
  }

  chargeOffSelected() {
    this.showDefault = false;
    this.showChargeOff = true;
  }

  navigateToNextStep() {
    this.router.navigateByUrl('payments')
  }

  modifyClicked() {
    // this.modifySelected = true;
    console.log('Trying to Route')
    this.router.navigateByUrl('payments')
  }

  cancelClicked() {
    this.modifySelected = false;
  }

}
