<!-- Default -->
<section *ngIf="showDefault" id="content" class="container-fluid container-xl mt-4 mt-lg-5">
  <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xxl-8">
          <div class="d-flex justify-content-between bg-danger text-white">
              <div class="ms-2 me-auto align-self-center">
                  <h2 class="fs-5 m-0 p-3 text-capitalize">
                      <i class="fal fa-bell-exclamation fa-bounce"></i>
                      <span class="d-none d-md-inline-block px-2">Your Account needs attention!</span> 
                  </h2>
              </div>
          </div>
          <div class="bg-white shadow-lg p-3 p-md-4">
              <div class="row">
                  <div class="col-12 col-md-6 align-self-center">
                      <h4 class="fw-normal text-secondary m-0">
                          (( Merchant Name ))
                      </h4>
                      <p class="m-0"><strong>Account Number:</strong>
                          895-9658-9856-98</p>

                  </div>
                  <div class="col-12 col-sm-7 col-md-6 align-self-center mt-4 mt-sm-0 d-none d-md-block">
                      <div id="merchant-info-dt">
                          <div class="row">
                              <div class="col-3 col-sm-4 align-self-center">
                                  <div id="info-profile" class="text-start">
                                      <div id="profile-picture-dt"
                                          class="text-white rounded-circle bg-secondary-light mx-auto d-flex justify-content-center border border-5 border-secondary fw-bolder">
                                          <div class="align-self-center fs-1">
                                              <a href="tell:555.555.5555" class="link-white"><i
                                                      class="fa-light fa-user-headset fa-lg"></i></a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-9 col-sm-8 align-self-center">
                                  <p class="m-0 fs-5">Talk to Your Agent.</p>
                                  <p class="m-0"><strong class="fs-3">555.555.5555</strong>
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <p>Below are options to help you reconcile your account.</p>
                  </div>
              </div>
              <div class="col-12">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="option-one-tab" data-bs-toggle="tab"
                              data-bs-target="#option-one" type="button" role="tab" aria-controls="option-one"
                              aria-selected="true"><strong>Option One</strong></button>
                      </li>
                      <li class="nav-item" role="presentation">
                          <button class="nav-link" id="option-two-tab" data-bs-toggle="tab"
                              data-bs-target="#option-two" type="button" role="tab" aria-controls="option-two"
                              aria-selected="false"><strong>Option
                                  Two</strong></button>
                      </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="option-one" role="tabpanel"
                          aria-labelledby="option-one-tab">

                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col" colspan="2">
                                          <h3 class="fs-5 text-capitalize mt-3">Settlement</h3>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td scope="row">
                                          <strong>Original Balance</strong>
                                      </td>
                                      <td class="text-end">
                                          $140.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td scope="row">
                                          <strong>Factor Fee</strong>
                                      </td>
                                      <td class="text-end">
                                          $20.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td scope="row">
                                          <strong>Default Fees</strong>
                                      </td>
                                      <td class="text-end">
                                          $20.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td scope="row">
                                          <strong>Subtotal</strong>
                                      </td>
                                      <td class="text-end">
                                          $140.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td scope="row">
                                          <strong>Discounted Fees</strong>
                                      </td>
                                      <td class="text-end">
                                          -$40.00
                                      </td>
                                  </tr>
                                  <tr class="fs-5">
                                      <td scope="row">
                                          <strong>Total Due</strong>
                                      </td>
                                      <td class="text-end">
                                          <strong>$100.00</strong>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <div class="row">
                              <div class="col-12 text-center ">
                                  <a (click)="navigateToNextStep()" role="button"
                                      class="btn btn-primary btn-lg mt-3">Settle My
                                      Account</a>
                                  <p class="mt-2"><i class="fa-regular fa-lock"></i> Secure Payments
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="tab-pane fade" id="option-two" role="tabpanel" aria-labelledby="option-two-tab">
                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col" colspan="2">
                                          <h3 class="fs-5 text-capitalize mt-3">Make a Payment</h3>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td scope="row">
                                          <strong>Original Balance</strong>
                                      </td>
                                      <td class="text-end">
                                          $240.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td scope="row">
                                          <strong>Current Balance</strong>
                                      </td>
                                      <td class="text-end">
                                          $100.00
                                      </td>
                                  </tr>
                                  <tr>
                                      <td scope="row">
                                          <strong>Daily Payment Amount</strong>
                                      </td>
                                      <td class="text-end">
                                          $8.00
                                      </td>
                                  </tr>
                                  <tr class="text-danger">
                                      <td scope="row">
                                          <strong>Past Due Amount</strong>
                                      </td>
                                      <td class="text-end">
                                          $80.00
                                      </td>
                                  </tr>
                                  <tr class="fs-5">
                                      <td scope="row">
                                          <strong>Due Today</strong>
                                      </td>
                                      <td class="text-end text-primary">
                                          <strong>$20.00</strong>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <div class="row">
                              <div class="col-12 text-center ">
                                  <a (click)="navigateToNextStep()" role="button" class="btn btn-primary btn-lg mt-3">Make a
                                      Payment</a>
                                  <p class="mt-2"><i class="fa-regular fa-lock"></i> Secure Payments
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-4 mb-9">
              <div class="col-12 px-4">
                  <p class="text-black-50 fs-6 lh-sm mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.
                      Nunc
                      ut est
                      sit amet purus porttitor scelerisque et et lectus. Aenean dignissim dictum hendrerit. Proin
                      quis
                      neque
                      tristique, interdum enim quis, pulvinar nunc. Integer
                      eget sagittis ipsum. Nunc ullamcorper nibh vel tortor posuere aliquam. Maecenas sed metus
                      sollicitudin,
                      tincidunt ex
                      vel, consequat.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="fixed-bottom bg-white text-center d-block d-md-none py-4">
      <a href="tell:555.555.5555" class="btn btn-xl btn-secondary rounded-pill shadow-lg"><i
              class="fa-light fa-user-headset fa-lg"></i> Call Agent</a>
  </div>
</section>


<!-- Charge Offs -->
<section *ngIf="showChargeOff" id="content" class="container-fluid container-xl mt-4 mt-lg-5">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xxl-8">
      <div class="d-flex justify-content-between bg-danger text-white">
        <div class="ms-2 me-auto align-self-center">
          <h2 class="fs-5 m-0 p-3 text-capitalize">
            <i class="fal fa-bell-exclamation fa-bounce"></i>
            <span class="d-none d-md-inline-block px-2">Your Account needs attention!</span>
          </h2>
        </div>
      </div>
      <div class="bg-white shadow-lg p-3 p-md-4">
        <div class="row">
          <div class="col-12 col-md-6 align-self-center">
            <h4 class="fw-normal text-secondary m-0">
              (( Merchant Name ))
            </h4>
            <p class="m-0"><strong>Account Number:</strong>
              895-9658-9856-98</p>

          </div>
          <div class="col-12 col-sm-7 col-md-6 align-self-center mt-4 mt-sm-0 d-none d-md-block">
            <div id="merchant-info-dt">
              <div class="row">
                <div class="col-3 col-sm-4 align-self-center">
                  <div id="info-profile" class="text-start">
                    <div id="profile-picture-dt"
                      class="text-white rounded-circle bg-secondary-light mx-auto d-flex justify-content-center border border-5 border-secondary fw-bolder">
                      <div class="align-self-center fs-1">
                        <a href="tell:555.555.5555" class="link-white"><i
                            class="fa-light fa-user-headset fa-lg"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-9 col-sm-8 align-self-center">
                  <p class="m-0 fs-5">Talk to Your Agent.</p>
                  <p class="m-0"><strong class="fs-3">555.555.5555</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>Below are options to help you reconcile your account.</p>
          </div>
        </div>
        <div class="col-12">


          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" colspan="2">
                  <h3 class="fs-5 text-capitalize mt-3">Settlement</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">
                  <strong>Original Balance</strong>
                </td>
                <td class="text-end">
                  $140.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Factor Fee</strong>
                </td>
                <td class="text-end">
                  $20.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Default Fees</strong>
                </td>
                <td class="text-end">
                  $20.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Subtotal</strong>
                </td>
                <td class="text-end">
                  $140.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Discounted Fees</strong>
                </td>
                <td class="text-end">
                  -$40.00
                </td>
              </tr>
              <tr class="fs-5">
                <td scope="row">
                  <strong>Total Due</strong>
                </td>
                <td class="text-end">
                  <strong>$100.00</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-12 text-center ">
              <a href="duco-sign.html" role="button" class="btn btn-primary btn-lg mt-3">Settle My
                Account</a>
              <p class="mt-2"><i class="fa-regular fa-lock"></i> Secure Payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-9">
      <div class="col-12 px-4">
        <p class="text-black-50 fs-6 lh-sm mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing
          elit.
          Nunc
          ut est
          sit amet purus porttitor scelerisque et et lectus. Aenean dignissim dictum hendrerit. Proin
          quis
          neque
          tristique, interdum enim quis, pulvinar nunc. Integer
          eget sagittis ipsum. Nunc ullamcorper nibh vel tortor posuere aliquam. Maecenas sed metus
          sollicitudin,
          tincidunt ex
          vel, consequat.</p>
      </div>
    </div>
  </div>
  <div class="fixed-bottom bg-white text-center d-block d-md-none py-4">
    <a href="tell:555.555.5555" class="btn btn-xl btn-secondary rounded-pill shadow-lg"><i
        class="fa-light fa-user-headset fa-lg"></i> Call Agent</a>
  </div>
</section>