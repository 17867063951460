<div class="text-center">
  <div class="fs-5 spinner-border text-secondary mb-5 mt-5" role="status" style="width: 5rem; height: 5rem;">
    <span class="visually-hidden">Cargando...</span>
  </div>
  <h1 *ngIf="validationText" class="fw-normal text-secondary fs-2">
    Un momento por favor
  </h1>
  <h2 class="fs-5 text-primary pb-2">Validando la información de tu tarjeta de débito</h2>
</div>

