import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { PaymentsComponent } from './screens/payments/payments.component';
import { ConfirmationComponent } from './screens/confirmation/confirmation.component';
import { NotFoundComponent } from './screens/not-found/not-found.component';

const routes: Routes = [
  { path: "", redirectTo: "404", pathMatch: 'full'},
  { path: "404", component: NotFoundComponent },
  { path: ":id", component: PaymentsComponent },
  { path: "confirmation", component: ConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
