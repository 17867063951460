import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-capture',
  templateUrl: './card-capture.component.html',
  styleUrls: ['./card-capture.component.scss']
})
export class CardCaptureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
