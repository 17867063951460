<nav id="main-nav" class="p-0 bg-white shadow-sm navbar navbar-expand-lg navbar-light sticky-top" role="navigation">
  <div class="container-fluid container-xxl">
      <a class="navbar-brand" href="/">
          <img src="https://hydra.design/_alz/assets/brand/logo-full.svg" width="180"
              class="mb-3 align-middle d-none d-lg-inline-block" alt="Alentaz Logo Full Color Desktop" loading="lazy">
          <img src="https://hydra.design/_alz/assets/brand/icon-full.svg" width="48"
              class="py-1 align-middle d-inline-block d-lg-none" alt="Alentaz Logo Full Color Mobile" loading="lazy">
      </a>
      <div class="d-flex">
          <!-- <a href="/" class="btn btn-outline-primary-dark align-self-center me-1 me-lg-3" role="button">
              <i class="fa-light fa-house-chimney"></i>
          </a> -->
          <!-- <a href="/blog" class="btn btn-outline-primary-dark align-self-center me-1 me-lg-3" role="button">
              Recursos
          </a>
          <a href="/blog/hola" class="btn btn-primary-dark align-self-center me-1 me-lg-3"
              role="button">
              Descubre
              <i class="fa-solid fa-right-long"></i>
          </a>    -->
      </div>
  </div>
</nav>
