// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // DEV
  conektaKey: 'key_mLoaz81FQ6k8NRI2W1gqrOT',
  conektaPublicKey: 'key_BkBTKaN7N6nYoVYnV7XJ2mC',
  apiBaseUrl: "https://api-dev.advance43.com/"
};
