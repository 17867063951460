<section id="content" class="container-fluid container-xl mt-4 mt-lg-5">
  <!-- {{confirmationData | json}} -->
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xxl-8">
      <div class="d-flex justify-content-between bg-primary text-white">
        <div class="ms-2 me-auto align-self-center">
          <h2 class="fs-5 m-0 p-3 text-capitalize">
            <i class="fal fa-circle-check fa-bounce"></i>
            <span class="d-none d-md-inline-block px-2">Thank you! Your Payment was successful!</span>
          </h2>
        </div>
      </div>
      <div class="bg-white shadow-lg p-3 p-md-4">
        <div class="row">
          <div class="col-12 col-md-6 align-self-center">
            <h4 class="fw-normal text-secondary m-0">
              {{confirmationData.billTo.firstName}} {{confirmationData.billTo.lastName}}
            </h4>
            <p class="m-0"><strong>Account Number:</strong>
              {{confirmationData.customerId}}</p>

          </div>
          <div class="col-12 col-sm-7 col-md-6 align-self-center mt-4 mt-sm-0 d-none d-md-block">
            <div id="merchant-info-dt">
              <div class="row">
                <div class="col-3 col-sm-4 align-self-center">
                  <div id="info-profile" class="text-start">
                    <div id="profile-picture-dt"
                      class="text-white rounded-circle bg-secondary-light mx-auto d-flex justify-content-center border border-5 border-secondary fw-bolder">
                      <div class="align-self-center fs-1">
                        <a href="tell:555.555.5555" class="link-white"><i
                            class="fa-light fa-user-headset fa-lg"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-9 col-sm-8 align-self-center">
                  <p class="m-0 fs-5">Talk to Your Agent.</p>
                  <p class="m-0"><strong class="fs-2">555.555.5555</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <hr>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <p>Below are the details of your transaction.</p>
            <hr>
            <p>Card: {{confirmationData.accountNumber}}</p>
            <p>Card Type: {{confirmationData.accountType}}.</p>
            <p>Description: {{confirmationData.orderDescription}}.</p>
            <p>Invoice Number: {{confirmationData.orderInvoiceNumber}}</p>
            <p>Confirmation Number: {{confirmationData.transId}}</p>
            <p></p>
            <p></p>
          </div>
        </div> -->
        <div class="col-12">


          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" colspan="2">
                  <h3 class="fs-5 text-capitalize mt-3">Settlement</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">
                  <strong>Original Balance</strong>
                </td>
                <td class="text-end">
                  $140.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Factor Fee</strong>
                </td>
                <td class="text-end">
                  $20.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Default Fees</strong>
                </td>
                <td class="text-end">
                  $20.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Subtotal</strong>
                </td>
                <td class="text-end">
                  $180.00
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <strong>Discounted Fees</strong>
                </td>
                <td class="text-end">
                  -$40.00
                </td>
              </tr>
              <tr class="fs-5">
                <td scope="row">
                  <strong>Total</strong>
                </td>
                <td class="text-end">
                  <strong>$140.00</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-12 text-center ">
              <a href="duco-sign.html" role="button" class="btn btn-primary btn-lg mt-3">Print Confirmation</a>
              <div>
                <a (click)="navigateToAccountSummary()" role="button" class="btn btn-secondary btn-lg mt-3">Return to Account Summary</a>
              </div>
              <p class="mt-2"><i class="fa-regular fa-lock"></i> Secure Payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-9">
      <div class="col-12 px-4">
        <p class="text-black-50 fs-6 lh-sm mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing
          elit.
          Nunc
          ut est
          sit amet purus porttitor scelerisque et et lectus. Aenean dignissim dictum hendrerit. Proin
          quis
          neque
          tristique, interdum enim quis, pulvinar nunc. Integer
          eget sagittis ipsum. Nunc ullamcorper nibh vel tortor posuere aliquam. Maecenas sed metus
          sollicitudin,
          tincidunt ex
          vel, consequat.</p>
      </div>
    </div>
  </div>
  <div class="fixed-bottom bg-white text-center d-block d-md-none py-4">
    <a href="tell:555.555.5555" class="btn btn-xl btn-secondary rounded-pill shadow-lg"><i
        class="fa-light fa-user-headset fa-lg"></i> Call Agent</a>
  </div>
</section>
